import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class EtagiDataInterceptor implements HttpInterceptor {
	intercept<T>(
		request: HttpRequest<T>,
		next: HttpHandler,
	): Observable<HttpEvent<T>> {
		const regExp = RegExp(/new.etagidata.ru/gm);
		const match = regExp.test(request.url);

		if (!match) {
			return next.handle(request);
		}

		request = request.clone({
			setHeaders: { etagi: '1' },
		});

		return next.handle(request);
	}
}
