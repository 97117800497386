import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AuthGuard } from '@emrm/core/auth/guards';
import {
	PermissionAppGuard,
	PermissionResolver,
} from '@emrm/core/permissions/guards';
import { REPORTS_APP_CODE } from '@emrm/reports/types';
import { HANDBOOKS_APP_CODE } from '@emrm/handbooks/types';
import { BUDGETS_APP_CODE } from '@emrm/budgets/types';
import { FILE_STORAGE_APP_CODE } from '@emrm/file-storage/types';
import { MANAGE_APP_CODE } from '@emrm/manage/types';
import { LEADS_MONITOR_APP_CODE } from '@emrm/leads-monitor/types';

export const routes: Route[] = [
	{
		path: '',
		title: 'Etagi MRM',
		canActivate: [AuthGuard],
		resolve: { permissions: PermissionResolver },
		loadComponent: () =>
			import('@emrm/core/pages/bootstrap').then(
				(c) => c.BootstrapPageComponent,
			),
		children: [
			{
				path: '',
				title: 'Etagi MRM',
				canActivate: [AuthGuard],
        loadComponent: () =>
					import('@emrm/homepage').then((m) => m.HomepageComponent),
			},
			{
				path: 'manage',
				title: 'Etagi MRM Пользователи',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: MANAGE_APP_CODE },
				loadChildren: () =>
					import('@emrm/manage/module').then((m) => m.ManageModule),
			},
			{
				path: 'reports',
				title: 'Etagi MRM Сводка',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: REPORTS_APP_CODE },
				loadChildren: () =>
					import('@emrm/reports/module').then((m) => m.ReportsModule),
			},
			{
				path: 'requests',
				title: 'Etagi MRM Заявки',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: LEADS_MONITOR_APP_CODE },
				loadChildren: () =>
					import('@emrm/leads-monitor/module').then(
						(m) => m.LeadsMonitorModule,
					),
			},
			{
				path: 'handbooks',
				title: 'Etagi MRM Справочники',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: HANDBOOKS_APP_CODE },
				loadChildren: () =>
					import('@emrm/handbooks/module').then((m) => m.HandbooksModule),
			},
			{
				path: 'budgets',
				title: 'Etagi MRM Бюджеты',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: BUDGETS_APP_CODE },
				loadChildren: () =>
					import('@emrm/budgets/module').then((m) => m.BudgetsModule),
			},
			{
				path: 'storage',
				title: 'Etagi MRM Контент',
				canActivate: [AuthGuard, PermissionAppGuard],
				data: { appCode: FILE_STORAGE_APP_CODE },
				loadChildren: () =>
					import('@emrm/file-storage/module').then((m) => m.FSModule),
			},
			{
				path: 'faq',
				title: 'Etagi MRM Помощь',
				canActivate: [AuthGuard],
				loadChildren: () => import('@emrm/faq/module').then((m) => m.FaqModule),
			},
      {
        path: 'news',
        title: 'Etagi MRM Новости',
        canActivate: [AuthGuard],
        loadChildren: () => import('@emrm/news/module').then((m) => m.NewsModule),
      },
			{
				path: '403',
				title: 'Etagi MRM',
				loadComponent: () =>
					import('@emrm/core/pages/access-denied').then(
						(c) => c.AccessDeniedPageComponent,
					),
			},
			{
				path: '404',
				title: 'Etagi MRM',
				loadComponent: () =>
					import('@emrm/core/pages/not-found').then(
						(c) => c.NotFoundPageComponent,
					),
			},
		],
	},
	{
		path: 'auth',
		title: 'Etagi MRM Авторизация',
		loadComponent: () =>
			import('@emrm/core/auth/pages/login').then((m) => m.LoginComponent),
	},
	{ path: '**', redirectTo: '404' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
