import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '@emrm/core/auth/service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept<T>(
		request: HttpRequest<T>,
		next: HttpHandler,
	): Observable<HttpEvent<T>> {
		if (request.headers.get('Authorization')) {
			return next.handle(request);
		}

		const token = this.authService.getToken();

		if (token) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${token}` },
			});
		}

		return next.handle(request);
	}
}
