import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, catchError } from 'rxjs';

import { AuthService } from '@emrm/core/auth/service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept<T>(
		request: HttpRequest<T>,
		next: HttpHandler,
	): Observable<HttpEvent<T>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.authService.logout();
				}

				return throwError(() => error);
			}),
		);
	}
}
